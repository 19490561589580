import { useEffect, useState } from 'react';
import { Image, Avatar, Dropdown, Menu, Typography } from 'antd';
import { CaretDownOutlined, LogoutOutlined } from '@ant-design/icons';
import styles from './index.module.less';
import { logout } from 'features/login/redux/slice';
import { useDispatch } from 'react-redux';
import { getLoggedInUser } from 'utilities/helpers';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import nucleausLogo from '../../images/new.png';
import gravitiLogo from '../../images/logo_backup.svg';
import CustomButton from '@/common/CustomButton';
import { WarningOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
const { Text } = Typography;

const UserMenu = () => {
  const dispatch = useDispatch();

  const signOutClickHandler = () => {
    dispatch(logout());
  };

  const user = getLoggedInUser();

  const [ismodal, setIsmodal] = useState(false);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     console.log(user);
  //   }, 10000000000000);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  const [state, setState] = useState('Active');
  const [count1, setCount1] = useState(0);
  const [remaining, setRemaining] = useState(0);

  const onIdle = () => {
    setState('Idle');
  };

  const onActive = () => {
    setState('Active');
  };

  const onAction = () => {
    setCount1(count1 + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 10 * 60 * 1000,
    throttle: 500
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
      handleAutoLogout();
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleAutoLogout = () => {
    if (remaining === 0 && state === 'Idle') {
      dispatch(logout());
    }
  };
  // console.log(remaining);
  const menu = (
    <Menu style={{ width: '130px' }}>
      <Menu.Item key="1">
        <span>
          User ID: <b>{user?.user?.employee}</b>
        </span>
      </Menu.Item>
      <Menu.Item key="2">
        <span>
          User Level: <b>{user?.user?.level}</b>
        </span>
      </Menu.Item>
      {user?.user?.role === 'admin' ? (
        <Menu.Item key="3" onClick={() => setIsmodal(true)}>
          <span>About Nucleus</span>
        </Menu.Item>
      ) : (
        ''
      )}
      <Menu.Item
        onClick={() => signOutClickHandler()}
        key="2"
        icon={<LogoutOutlined />}
      >
        <Text>Logout</Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown className={`${styles.icon_color}`} overlay={menu}>
        <div className={`df-jb-ac ${styles.user_menu_container}`}>
          <Image
            className={`${styles.gr_logo_header}`}
            alt="Graviti logo"
            src={gravitiLogo}
            preview={false}
          />
          <Avatar className={styles.user_menu_avatar}>
            {user?.user ? user?.user?.fullname.charAt(0).toUpperCase() : ''}
          </Avatar>
          <CaretDownOutlined />
        </div>
      </Dropdown>
      {ismodal ? (
        <CustomModal
          // title="Release Notes"
          visible={ismodal}
          width="30%"
          footer={null}
          className={styles.customModal}
          closable={false}
        >
          <FormItem className="text-center">
            <Image
              className={`${styles.logo_header}`}
              alt="Graviti logo"
              src={nucleausLogo}
              preview={false}
            />
          </FormItem>
          <FormItem className="text-center" style={{ color: 'black' }}>
            <div>Application: Nucleus Manufacturing Automation Solution</div>

            <div>Licensed to: Graviti Pharmaceuticals Private Limited</div>
            <div>Owned by: Graviti Pharmaceuticals Private Limited</div>
            <div>Release: 2.1.1 (Latest)</div>
            <br />
            <div>
              <Link
                to="/Releasenotes.pdf"
                target="_blank"
                download
                style={{ fontSize: '17px', color: '#e80e94' }}
                onClick={() => setIsmodal(false)}
              >
                See Release Notes
              </Link>
            </div>
          </FormItem>
          <FormItem className="text-center">
            <p style={{ fontSize: '10px' }}>
              <WarningOutlined style={{ color: '#ca6363' }} />{' '}
              <span style={{ color: 'grey' }}>
                Warning: This computer program is protected by copyright law and
                international treaties. Unauthorized reproduction or
                distribution of this program, or any portion of it, may result
                in severe civil and criminal penalties, and will be prosecuted
                to the maximum extent possible under the law.
              </span>
            </p>
            <CustomButton
              className={styles.inwardButton}
              onClick={() => setIsmodal(false)}
              type="primary"
              size="small"
            >
              Close
            </CustomButton>
            <br />
            <br />
          </FormItem>
          {/* <FormItem className="text-center"> */}

          {/* </FormItem> */}
        </CustomModal>
      ) : (
        ''
      )}
    </>
  );
};

export default UserMenu;
